<template>
  <div class="d-flex flex-column">
    <campus-create-modal :create-campus-active.sync="createCampusActive" @refetch-data="refetchData" />

    <campus-edit-modal :edit-campus-active.sync="editCampusActive" :campus-data="editCampusData" @refetch-data="refetchData" />

    <div class="d-flex content-px my-1">
      <b-row class="d-flex w-100">
        <b-col cols="12" class="d-flex justify-content-between align-items-center w-100 pr-0">
          <div class="d-flex align-items-center" style="gap: 0.675rem">
            <b-form-input v-model="searchQuery" class="drop-shadow-default" placeholder="Search..." style="max-width: 28rem" />
            <campus-list-filters
              :type-filter.sync="typeFilter"
              :status-filter.sync="statusFilter"
              :type-options="typeOptions"
              :status-options="statusOptions"
            />
          </div>

          <b-button
            v-if="$can('site.campus.create')"
            variant="primary"
            @click="createCampusActive = true"
            class="ml-1"
            style="line-height: 1rem; font-size: 1rem"
          >
            <feather-icon icon="PlusCircleIcon" size="16" class="mr-25" />
            <span class="text-nowrap">New Campus</span>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-card no-body class="table-no-top rounded-0 border-left-0 border-right-0">
      <b-table
        ref="refCampusListTable"
        class="position-relative mb-0"
        :items="fetchCampusList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @row-contextmenu="onRowContextMenu"
      >
        <!-- Column: Campus -->
        <template #cell(name)="data">
          <div class="d-flex align-items-center" style="gap: 0.625rem">
            <b-avatar
              size="36"
              :src="data.item.avatar"
              :text="avatarText(data.item.name)"
              :variant="`light-info`"
              :to="{ name: 'apps-sites-campus-view', params: { id: data.item.id } }"
              rounded
            />
            <div class="d-flex flex-column" style="gap: 0">
              <b-link
                class="font-weight-bold text-nowrap text-white mb-0"
                :to="{ name: 'apps-sites-campus-view', params: { id: data.item.id } }"
                style="font-size: 1.25rem; line-height: 1.75rem"
              >
                {{ data.item.name }}
              </b-link>
              <!-- <small class="text-muted" style="font-size: 1.05rem; line-height: 1.35rem">0 Sections</small> -->
              <!-- <small class="text-muted" style="font-size: 1.05rem; line-height: 1.35rem"
                >{{ data.item.sectionCounts.total }} Sections</small
              > -->
            </div>
          </div>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          <b-badge :variant="`${resolveCampusTypeVariant(data.item.type)}`" class="text-capitalize">
            {{ data.item.type }}
          </b-badge>
        </template>

        <!-- Column: Address -->
        <template #cell(address)="data">
          <span v-if="data.item.type === 'physical'" class="text-capitalize">
            {{ data.item.address.street1 }}, {{ data.item.address.street2 }}, {{ data.item.address.city }}, {{ data.item.address.state }}
            {{ data.item.address.zip }}
          </span>
          <span v-else class="text-capitalize">Not Applicable</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveCampusStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="primary" class="dropdown-edit-button">
            <template #button-content>
              <div class="p-50">
                <feather-icon icon="MoreHorizontalIcon" size="21" />
              </div>
            </template>

            <b-dropdown-item @click="editCampus(data.item)">
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Edit</span>
                <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-sites-campus-view', params: { id: data.item.id } }">
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>View</span>
                <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-row class="m-0 px-2 pb-1">
        <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="mb-0 text-muted">{{ dataMeta.of }} Total Entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
          <div class="mr-2 vue-select-up">
            <span class="mb-0 mr-75">Rows Per Page</span>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block"
              :open="true"
            />
          </div>
          <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPageCount }}</span>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalCampus"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-sm-0 ml-lg-50"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>

    <vue-context ref="campusListMenu" class="mw-8" v-slot="{ data }">
      <li v-if="data">
        <a
          class="d-flex align-items-center justify-content-between"
          @click="$router.push({ name: 'apps-sites-campus-view', params: { id: data.item.id } })"
        >
          <span>View</span>
          <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
        </a>
        <a class="d-flex align-items-center justify-content-between" @click="editCampus(data.item)">
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import CampusListFilters from './CampusListFilters.vue';
import useCampus from '../useCampus';
import VueContext from 'vue-context';
import CampusEditModal from '../edit/CampusEditModal.vue';
import CampusCreateModal from '../create/CampusCreateModal.vue';

export default {
  components: {
    CampusListFilters,
    CampusCreateModal,
    CampusEditModal,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    VueContext,
  },
  methods: {
    editCampus(data) {
      this.editCampusData = data;
      this.editCampusActive = true;
    },
    onRowContextMenu(item, index, event) {
      this.$refs.campusListMenu.open(event, { item });
      event.preventDefault();
    },
  },
  setup() {
    const editCampusData = ref({});
    const editCampusActive = ref(false);
    const createCampusActive = ref(false);

    const {
      fetchCampusList,
      tableColumns,
      perPage,
      currentPage,
      totalCampus,
      totalPageCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCampusListTable,
      refetchData,

      // UI
      resolveCampusStatusVariant,
      resolveCampusTypeVariant,

      typeOptions,
      statusOptions,

      // Extra Filters
      typeFilter,
      statusFilter,
    } = useCampus();

    return {
      fetchCampusList,
      tableColumns,
      perPage,
      currentPage,
      totalPageCount,
      totalCampus,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCampusListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveCampusStatusVariant,
      resolveCampusTypeVariant,

      typeOptions,
      statusOptions,

      // Extra Filters
      typeFilter,
      statusFilter,

      editCampusData,
      editCampusActive,
      createCampusActive,
    };
  },
  metaInfo() {
    return {
      title: 'Campus',
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
../useCampus../edit/CampusEdit.vue../create/CampusCreateModal.vue./CampusListFilters.vue
