<template>
  <b-modal
    ref="modalCreateCampus"
    :visible="createCampusActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`New Campus`"
    ok-title="Create Campus"
    centered
    body-class="pt-0"
    @ok="createEntity"
  >
    <validation-observer ref="refFormObserver">
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <validation-provider #default="validationContext" name="Campus Name" rules="required">
            <b-form-group label="Campus Name" label-for="campus-name">
              <b-form-input
                required
                autofocus
                id="campus-name"
                placeholder=""
                v-model="campusEditData.name"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Campus Description" label-for="campus-description">
            <b-form-input autofocus id="campus-description" placeholder="" v-model="campusEditData.description" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Campus Avatar URL" label-for="campus-avatar">
            <b-form-input id="campus-avatar" placeholder="" v-model="campusEditData.avatar" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Campus Picture URL" label-for="campus-picture">
            <b-form-input id="campus-picture" placeholder="" v-model="campusEditData.pictureUrl" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Status" label-for="campus-status">
            <v-select
              v-model="campusEditData.status"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              required
              transition="smooth"
              input-id="campus-status"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="6">
          <b-form-group label="Campus Type" label-for="campus-type">
            <v-select
              v-model="campusEditData.type"
              :reduce="(val) => val.value"
              :options="typeOptions"
              :clearable="false"
              transition="smooth"
              input-id="campus-type"
            />
          </b-form-group>
        </b-col>

        <b-row v-if="campusEditData.type === 'physical'">
          <b-col cols="12" md="6" lg="12" class="d-flex border-top h-1 mt-50 mb-2"></b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group label="Campus Street 1" label-for="campus-address-street1">
              <b-form-input
                required
                id="campus-address-street1"
                placeholder="123 Main St"
                v-model="campusEditData.address.street1"
                :state="campusEditData.address.street1 ? null : false"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="2">
            <b-form-group label="Campus Street 2" label-for="campus-address-street2">
              <b-form-input required id="campus-address-street2" placeholder="Apt 650" v-model="campusEditData.address.street2" />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="2">
            <b-form-group label="Campus City" label-for="campus-address-city">
              <b-form-input
                required
                id="campus-address-city"
                placeholder="Sterling"
                v-model="campusEditData.address.city"
                :state="campusEditData.address.city ? null : false"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group label="Campus State" label-for="campus-address-state" :state="campusEditData.address.state ? null : false">
              <v-select
                v-model="campusEditData.address.state"
                :options="states"
                :clearable="false"
                required
                transition="smooth"
                input-id="campus-address-state"
                class="capped-height"
                placeholder="Virginia"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="2">
            <b-form-group label="Campus Zip Code" label-for="campus-address-zip">
              <b-form-input
                required
                id="campus-address-zip"
                placeholder="20190"
                v-model="campusEditData.address.zip"
                :state="campusEditData.address.zip ? null : false"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BFormInvalidFeedback,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import store from '@/store';
import siteStoreModule from '@/store/site/siteStoreModule';
import { ref } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useCampus from '../useCampus';
import vSelect from 'vue-select';
import states from '@/libs/data/states';

export default {
  name: 'CampusEditModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BFormInvalidFeedback,
    BModal,
    quillEditor,
    vSelect,
    siteStoreModule,

    ValidationProvider,
    ValidationObserver,
  },
  directives: { 'b-modal': VBModal },
  props: {
    createCampusActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    '$props.createCampusActive'(val) {
      this.initModal();
    },
  },
  created() {
    this.initModal();
  },
  methods: {
    initModal() {
      this.campusEditData = {
        name: null,
        description: null,
        avatar: null,
        pictureUrl: null,
        type: 'virtual',
        status: 'created',
        address: {
          street1: null,
          street2: null,
          city: null,
          state: '',
          zip: null,
          country: null,
        },
      };
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:create-campus-active', false);
    },
    validateForm() {
      if (!this.campusEditData.name || !this.campusEditData.type) return false;

      if (
        this.campusEditData.type === 'physical' &&
        (!this.campusEditData.address.street1 ||
          !this.campusEditData.address.city ||
          !this.campusEditData.address.state ||
          !this.campusEditData.address.zip)
      ) {
        return false;
      }

      return true;
    },
    createEntity(event) {
      this.busy = true;
      event.preventDefault();

      const pl = { ...this.campusEditData };

      if (this.validateForm() !== true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Missing Required Fields',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Please fill out all required fields.',
          },
        });
        this.busy = false;
        return;
      }

      store
        .dispatch('siteStoreModule/createCampus', { campusData: pl })
        .then((response) => {
          this.$emit('update:campus-data', response.data);

          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Campus Created!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create campus',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  data() {
    return {
      states,
    };
  },
  setup(props) {
    const campusEditData = ref({});
    const busy = ref(false);

    const { refFormObserver, getValidationState, resetForm } = formValidation(campusEditData);
    const { statusOptions, typeOptions } = useCampus();

    return { statusOptions, typeOptions, campusEditData, busy, refFormObserver, getValidationState, resetForm };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

.vs__dropdown-menu {
  max-height: 250px !important;
}
</style>
